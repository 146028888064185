import React, { Component } from 'react';
import { isEmail } from 'validator';

import Paypal from '../../../images/donate/paypal.svg';
import Heart from '../../../images/donate/heart.png';
import Frame from '../../../images/donate/frame.png';
import Bg2 from '../../../images/donate/bg2.png';

import axios from 'axios';
import validator from 'validator';
import Error_img from '../../../images/error/Shape.svg';
import Error_line from '../../../images/error/line_header.png';

import nis from '../../../images/donate/nis.png';
import check from '../../../images/event/check.png';
import penImg from '../../../images/donate/pen.png';
import phoneImg from '../../../images/donate/phone.png';
import hetz from '../../../images/donate/arrows.png';
import RadioButtonKrembo from './RadioButtonKrembo';

export const DIFF_SUM = 'סכום אחר';
export const DIFF_SUM_EN = 'Other';

class Form extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            name: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            business_number: '',
            total: '',
            id: '',
            id_pristine: true,
            notes: '',
            subscribed: true,
            project: 'project',
            phone_pristine: true,
            name_pristine: true,
            radio_amount: undefined,
            total_pristine: true,
            email_pristine: true,
            bad_email: false,
            bad_phone: false,
            finished: false,
            finish_url: '',
            disabled_btn: false,
            firm_donation: false,
            business_number_pristine: true,
            bad_business_number: false,
            min_total_err: true,
            focused_total: false,
            focused_name: false,
            focused_phone: false,
            focused_address: false,
            focused_city: false,
            focused_email: false,
            focused_id: false,
            focused_area: false,
            enable_igul_letova: false,

            showMissingInputsMEssage: false,
        };
    }

    componentDidUpdate() {
        if (
            (this.state.radio_amount === DIFF_SUM ||
                this.state.radio_amount === DIFF_SUM_EN) &&
            !this.state.focus_input_amount
        ) {
            this.setState({ focus_input_amount: true });
            const input = document.getElementById('donation_amount_form__');
            if (input) input.focus();
        }
    }
    scroll = (e) => {
        this.setState({
            height_of_pop_up: window.scrollY - 120,
        });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        document.addEventListener('scroll', this.scroll);
        const url = window.location.pathname.split('/')[1];
        if (url === 'error') {
            this.setState({
                error_box: true,
            });
        }
        // Set parameters from url params
        // Create a URL object
        const my_url = new URL(window.location.href);
        // Get the search params from the URL
        const params = new URLSearchParams(my_url.search);

        // Extract individual parameters
        const name = params.get('name');
        const email = params.get('email') || '';
        const phone = params.get('phone');
        const project = params.get('project');
        const sum = params.get('sum');

        // Log or use the extracted parameters
        // console.log('Name:', name);
        // console.log('Email:', email);
        // console.log('Phone:', phone);
        // console.log('project:', project);
        // console.log('sum:', sum);

        if (sum && document.querySelector(`.sum-${sum}`)) {
            document.querySelector(`.sum-${sum}`).click();
        } else if (sum) {
            this.setState({
                total: +sum,
                focused_total: true,
            });
        }
        this.setState({
            name,
            phone,
            email,
            focused_name: true,
            focused_phone: true,
            focused_email: true,
        });

        if (project) this.props.setProjectName(project);
    }

    openPopUp = (e) => {
        e.preventDefault();
    };

    submitForm = (e) => {
        e.preventDefault();

        const {
            name,
            phone,
            city,
            address,
            email,
            total,
            notes,
            id,
            subscribed,
            radio_amount,
            round_up,
        } = this.state;
        // const { project } = this.props;
        if (!name) this.setState({ name_pristine: false });
        if (!phone) this.setState({ phone_pristine: false });
        if (
            !total &&
            !(
                radio_amount &&
                radio_amount !== DIFF_SUM &&
                radio_amount !== DIFF_SUM_EN
            )
        )
            this.setState({ total_pristine: false });
        if (!isEmail(email)) {
            this.setState({ email_pristine: false });
        }
        if (!id) this.setState({ id_pristine: false });
        if (
            total == 0 &&
            !(
                radio_amount &&
                radio_amount !== DIFF_SUM &&
                radio_amount !== DIFF_SUM_EN
            )
        )
            this.setState({ min_total_err: false });

        if (!validator.isEmail(email) && isEmail(email)) {
            this.setState({
                bad_email: true,
            });
        } else if (!validator.isMobilePhone(phone) && phone.length >= 1) {
            this.setState({
                bad_phone: true,
            });
        } else if (
            isEmail(email) &&
            phone &&
            name &&
            (total || (radio_amount && radio_amount !== DIFF_SUM)) &&
            id
        ) {
            this.setState({ enable_igul_letova: true });
            setTimeout(this.goToDonation, 500);
        } else {
            this.setState({
                showMissingInputsMEssage: true,
            });
        }
    };
    goToDonation = (round_up) => {
        const {
            name,
            phone,
            city,
            address,
            email,
            total,
            notes,
            id,
            subscribed,
            radio_amount,
            business_number,
        } = this.state;
        const { project } = this.props;
        // this.setState({
        //     round_up,
        //     enable_igul_letova: false,
        // });
        setTimeout(() => {
            this.setState({ disabled_btn: true });
            // axios.post(`https://api.krembo.org.il/donate`, { name, phone, email, total, notes, project, city, address }).then(res => {
            axios
                .post(
                    `https://api.krembo.org.il/donate`,
                    //   `http://localhost:3060/donate`,
                    {
                        name,
                        phone,
                        email,
                        business_number: id,
                        total:
                            radio_amount &&
                            radio_amount !== DIFF_SUM &&
                            radio_amount !== DIFF_SUM_EN
                                ? radio_amount
                                : total,
                        notes,
                        project,
                        city,
                        address,
                        id,
                        round_up,
                        subscribed,
                    },
                )
                .then((res) => {
                    if (res.data.ok) {
                        this.setState({ finished: true });
                        this.setState({
                            finish_url: res.result,
                        });

                        window.location.replace(res.data.result);
                        setTimeout(() => {
                            this.setState({
                                disabled_btn: false,
                            });
                        }, 4000);

                        setTimeout(() => {
                            this.setState({ submitted: true });
                        }, 7500);
                    } else if (
                        res.data.result === 'This email already exists'
                    ) {
                        this.setState({
                            global_warning: res.data.result,
                            tooltip: true,
                            submit: false,
                            finished: true,
                        });
                        const hidden_warning_email = document.querySelector(
                            '#hidden_warning_email',
                        );
                        hidden_warning_email.style.display = 'block';
                        setTimeout(() => {
                            hidden_warning_email.style.display = 'none';
                        }, 3000);
                    } else {
                        console.log(res);
                        alert(res.data.message);
                        this.setState({
                            registration_failed: true,
                            submit: false,
                            finished: true,
                        });
                    }
                });
        }, 200);
    };

    increment() {
        const { total, radio_amount } = this.state;
        if (
            radio_amount &&
            (radio_amount === DIFF_SUM || radio_amount === DIFF_SUM_EN)
        )
            this.setState({
                total: Number(total) + 50,
            });
    }

    decrement() {
        const { total, radio_amount } = this.state;
        if (
            radio_amount &&
            (radio_amount === DIFF_SUM || radio_amount === DIFF_SUM_EN) &&
            total >= 50
        )
            this.setState({
                total: Number(total) - 50,
            });
    }
    setRadio = (radio_amount) => {
        const { enable_igul_letova } = this.state;
        if (!enable_igul_letova)
            this.setState({
                radio_amount,
                total: '',
                focus_input_amount:
                    radio_amount === DIFF_SUM || radio_amount === DIFF_SUM_EN
                        ? false
                        : true,
            });
    };
    render() {
        const {
            focused_name,
            focused_phone,
            focused_address,
            focused_city,
            focused_email,
            focused_id,
            focused_area,
            focused_total,
            name,
            name_pristine,
            phone,
            phone_pristine,
            min_total_err,
            email,
            email_pristine,
            total,
            total_pristine,
            bad_email,
            bad_phone,
            disabled_btn,
            notes,
            id,
            id_pristine,
            address,
            city,
            subscribed,
            showMissingInputsMEssage,
            error_box,
            radio_amount,
            round_up,
            height_of_pop_up,
            enable_igul_letova,
        } = this.state;
        const is_mobile = window.innerWidth <= 728;
        const { lang, PROJECTS, toggle_select, project, selected_language } =
            this.props;
        return (
            <form onSubmit={this.submitForm}>
                {enable_igul_letova ? (
                    <div
                        // style={{
                        //     top: height_of_pop_up
                        //         ? `${height_of_pop_up}px`
                        //         : '',
                        // }}
                        className="pop_up_form_igul"
                    >
                        <h2>
                            {selected_language === 'English'
                                ? 'You are being redirected to the payment page...'
                                : 'הינך מועבר.ת לעמוד סליקה חיצונית לביצוע התרומה...'}
                        </h2>
                    </div>
                ) : (
                    ''
                )}
                {error_box ? (
                    <div className="error__box">
                        <div>
                            <img src={Error_img} alt="" />
                            <h1 style={{ textAlign: 'center' }}>
                                {selected_language === 'English'
                                    ? "Oops... It looks like there was an issue and your donation wasn't received. Your account wasn't charged."
                                    : 'אופס... נראה שהייתה בעיה ותרומתך לא התקבלה. חשבונך לא חוייב.'}
                            </h1>
                            {/* <h1 className='error__line'>
                {lang.bottom}
              </h1> */}
                            <aside
                                onClick={() =>
                                    this.setState({
                                        error_box: false,
                                    })
                                }
                                type="button"
                            >
                                {lang.try_again}
                            </aside>
                        </div>
                    </div>
                ) : null}
                <span className="donate__form__mobile__hidden">
                    <figure></figure>
                    <h1>{lang.h1}</h1>
                    <p>{lang.text1}</p>
                    <h5>{lang.small_text}</h5>
                </span>

                <div className="donate__info__flex">
                    {/* <h1>{lang.form_h1}​</h1> */}
                    <div></div>
                    <div className="donation__details ">
                        <h2 className="donati_amounti">
                            {selected_language === 'English'
                                ? 'Donation amount'
                                : 'סכום לתרומה'}
                        </h2>
                        <div className="radio_box_donati first_donati_box">
                            {[90, 180, 360].map((n) => (
                                <RadioButtonKrembo
                                    onClick={this.setRadio}
                                    radio_amount={radio_amount}
                                    amount={n}
                                />
                            ))}
                        </div>

                        <div className="radio_box_donati second_donati_box">
                            {[
                                500,
                                1000,
                                selected_language === 'English'
                                    ? DIFF_SUM_EN
                                    : DIFF_SUM,
                            ].map((n) => (
                                <RadioButtonKrembo
                                    onClick={this.setRadio}
                                    radio_amount={radio_amount}
                                    amount={n}
                                />
                            ))}
                            <div className="donation_amount-Radio">
                                <div
                                    onClick={() => {
                                        this.setState({
                                            radio_amount:
                                                selected_language === 'English'
                                                    ? DIFF_SUM_EN
                                                    : DIFF_SUM,
                                        });
                                    }}
                                    className={
                                        focused_total
                                            ? 'donation__amount focused__input'
                                            : 'donation__amount'
                                    }
                                >
                                    <input
                                        type="number"
                                        className="total_donation_input_other"
                                        id="donation_amount_form__"
                                        name="total"
                                        onChange={(e) =>
                                            e.target.value.toString().length &&
                                            !enable_igul_letova <= 6
                                                ? this.setState({
                                                      total: e.target.value,
                                                  })
                                                : ''
                                        }
                                        value={total < 1 ? '' : total}
                                        onFocus={() =>
                                            this.setState({
                                                total_pristine: true,
                                                min_total_err: true,
                                                focused_total: true,
                                            })
                                        }
                                    />
                                    {!total_pristine && !total ? (
                                        <h4 className="warning_msg">
                                            {lang.insert_total}
                                        </h4>
                                    ) : !min_total_err ? (
                                        <h4 className="warning_msg">
                                            {lang.min_amount}
                                        </h4>
                                    ) : null}
                                    <aside>
                                        <figure
                                            onClick={() => {
                                                this.setState({
                                                    focused_total: true,
                                                });
                                                this.increment();
                                            }}
                                        ></figure>
                                        <figure
                                            onClick={() => {
                                                this.setState({
                                                    focused_total: true,
                                                });
                                                this.decrement();
                                            }}
                                            className={
                                                total === 1
                                                    ? 'total__figure__blured'
                                                    : null
                                            }
                                        ></figure>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="donator__information">
                        <h2>{lang.person_details}</h2>
                        <span
                            className={focused_name ? 'focused__input' : null}
                        >
                            <input
                                type="text"
                                value={this.state.name}
                                onChange={(e) =>
                                    !enable_igul_letova
                                        ? this.setState({
                                              name: e.target.value,
                                          })
                                        : ''
                                }
                                onFocus={() =>
                                    this.setState({
                                        name_pristine: true,
                                        focused_name: true,
                                    })
                                }
                                onBlur={() =>
                                    this.setState({
                                        name_pristine: name?.length >= 1,
                                        focused_name: name?.length >= 1,
                                    })
                                }
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                            >
                                <g>
                                    <g opacity=".8">
                                        <path
                                            fill={
                                                focused_name
                                                    ? '#4a90e2'
                                                    : '#424242'
                                            }
                                            d="M18 10c0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8 8-3.58 8-8zm2 0c0 5.53-4.49 10-10.01 10S0 15.53 0 10 4.47 0 9.99 0C15.52 0 20 4.47 20 10zm-8.47-1.53a.744.744 0 0 1 .01-1.06l.883-.88a1 1 0 0 1 1.414 0l.882.88c.291.29.291.77 0 1.06a.758.758 0 0 1-1.063 0l-.531-.53-.531.53a.758.758 0 0 1-1.063 0zm-6.28 4.28c-.19-.33.06-.75.44-.75h8.63c.39 0 .63.42.44.75a5.495 5.495 0 0 1-4.755 2.75 5.495 5.495 0 0 1-4.755-2.75zm1.63-4.81l-.53.53c-.29.29-.77.29-1.06 0a.754.754 0 0 1 0-1.06l.89-.88a.996.996 0 0 1 1.41 0l.88.88c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0z"
                                        />
                                    </g>
                                </g>
                            </svg>
                            {!name_pristine && !name ? (
                                <h4 className="warning_msg">
                                    {lang.name_insert}
                                </h4>
                            ) : null}
                            <label className="form-label">
                                <span>{lang.name}</span>
                            </label>
                        </span>
                        <span
                            className={focused_phone ? 'focused__input' : null}
                        >
                            <input
                                type="number"
                                onChange={(e) =>
                                    !enable_igul_letova
                                        ? this.setState({
                                              phone: e.target.value,
                                          })
                                        : ''
                                }
                                value={this.state.phone}
                                onFocus={() =>
                                    this.setState({
                                        phone_pristine: true,
                                        bad_phone: false,
                                        focused_phone: true,
                                    })
                                }
                                onBlur={() =>
                                    this.setState({
                                        phone_pristine: phone.length >= 1,
                                        focused_phone: phone.length >= 1,
                                    })
                                }
                            />
                            {!phone_pristine && !phone ? (
                                <h4 className="warning_msg">
                                    {lang.phone_insert}
                                </h4>
                            ) : bad_phone && phone ? (
                                <h4 className="warning_msg">
                                    {lang.bad_phone}
                                </h4>
                            ) : null}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="22"
                                viewBox="0 0 16 22"
                            >
                                <g>
                                    <g opacity=".8">
                                        <path
                                            fill={
                                                focused_phone
                                                    ? '#4a90e2'
                                                    : '#424242'
                                            }
                                            fillOpacity=".8"
                                            d="M8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6-8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM2 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 18c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <label className="form-label">
                                <span>{lang.phone}</span>
                            </label>
                        </span>
                        <span
                            className={
                                focused_address ? 'focused__input' : null
                            }
                        >
                            <input
                                type="text"
                                onChange={(e) =>
                                    !enable_igul_letova
                                        ? this.setState({
                                              address: e.target.value,
                                          })
                                        : ''
                                }
                                value={this.state.address}
                                onFocus={() =>
                                    this.setState({
                                        focused_address: true,
                                    })
                                }
                                onBlur={() =>
                                    this.setState({
                                        focused_address: address.length >= 1,
                                    })
                                }
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                            >
                                <g>
                                    <g opacity=".8">
                                        <path
                                            fill={
                                                focused_address
                                                    ? '#4a90e2'
                                                    : '#424242'
                                            }
                                            d="M16 13.002h-2v-2h2zm0 4h-2v-2h2zm-6-12H8v-2h2zm0 4H8v-2h2zm0 4H8v-2h2zm0 4H8v-2h2zm-6-8H2v-2h2zm0 4H2v-2h2zm0 4H2v-2h2zm8-8v-5.17c0-.53-.21-1.04-.59-1.41L9.7.712a.996.996 0 0 0-1.41 0l-1.7 1.7c-.38.38-.59.89-.59 1.42v1.17H0v14h18v-10h-2z"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <label className="form-label">
                                <span>{lang.address}</span>
                            </label>
                        </span>
                        <span
                            className={focused_city ? 'focused__input' : null}
                        >
                            <input
                                type="text"
                                onChange={(e) =>
                                    !enable_igul_letova
                                        ? this.setState({
                                              city: e.target.value,
                                          })
                                        : ''
                                }
                                value={this.state.city}
                                onFocus={() =>
                                    this.setState({
                                        focused_city: true,
                                    })
                                }
                                onBlur={() =>
                                    this.setState({
                                        focused_city: city.length >= 1,
                                    })
                                }
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="20"
                                viewBox="0 0 14 20"
                            >
                                <g>
                                    <g opacity=".8">
                                        <path
                                            fill={
                                                focused_city
                                                    ? '#4a90e2'
                                                    : '#424242'
                                            }
                                            d="M7 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5zM7 0C3.13 0 0 3.13 0 7c0 4.17 4.42 9.92 6.24 12.11.4.48 1.13.48 1.53 0C9.58 16.92 14 11.17 14 7c0-3.87-3.13-7-7-7z"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <label className="form-label">
                                <span>{lang.city}</span>
                            </label>
                        </span>
                        <span
                            className={focused_email ? 'focused__input' : null}
                        >
                            <input
                                type="text"
                                className="email-input"
                                onChange={(e) =>
                                    !enable_igul_letova
                                        ? this.setState({
                                              email: e.target.value,
                                          })
                                        : ''
                                }
                                value={this.state.email}
                                onFocus={() =>
                                    this.setState({
                                        email_pristine: true,
                                        bad_email: false,
                                        focused_email: true,
                                    })
                                }
                                required
                                onBlur={() =>
                                    this.setState({
                                        email_pristine: isEmail(email),
                                        focused_email: email.length > 0,
                                    })
                                }
                            />
                            {!email_pristine && !isEmail(email) ? (
                                <h4 className="warning_msg">
                                    {lang.email_insert}
                                </h4>
                            ) : bad_email && email ? (
                                <h4 className="warning_msg">
                                    {lang.bad_email}
                                </h4>
                            ) : null}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="16"
                                viewBox="0 0 20 16"
                            >
                                <g>
                                    <g opacity=".8">
                                        <path
                                            fill={
                                                focused_email
                                                    ? '#4a90e2'
                                                    : '#424242'
                                            }
                                            d="M17.6 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L2.4 4.25a.85.85 0 1 1 .9-1.44L10 7l6.7-4.19a.85.85 0 1 1 .9 1.44zM18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <label className="form-label">
                                <span>{lang.email}</span>
                            </label>
                        </span>
                        <span className={focused_id ? 'focused__input' : null}>
                            <input
                                type="text"
                                value={id}
                                onChange={(e) =>
                                    !enable_igul_letova
                                        ? this.setState({
                                              id: e.target.value,
                                          })
                                        : ''
                                }
                                onFocus={() =>
                                    this.setState({
                                        focused_id: true,
                                        email_id: true,
                                        bad_id: false,
                                    })
                                }
                                onBlur={() =>
                                    this.setState({
                                        focused_id: id,
                                        id_pristine: id.length >= 1,
                                    })
                                }
                            />
                            {!id_pristine && !id ? (
                                <h4 className="warning_msg">
                                    {lang.id_insert}
                                </h4>
                            ) : null}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                            >
                                <g>
                                    <g opacity=".8">
                                        <path
                                            fill={
                                                focused_id
                                                    ? '#4a90e2'
                                                    : '#424242'
                                            }
                                            d="M16 14v1c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1v-1c0-2.66 5.33-4 8-4s8 1.34 8 4zM4 4c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4z"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <label className="form-label">
                                <span>{lang.id}</span>
                            </label>
                        </span>
                    </div>
                    <span
                        className={
                            focused_area
                                ? 'focused__input project__notes'
                                : 'project__notes'
                        }
                    >
                        <textarea
                            onChange={(e) =>
                                !enable_igul_letova
                                    ? this.setState({
                                          notes: e.target.value,
                                      })
                                    : ''
                            }
                            value={this.state.notes}
                            onFocus={() =>
                                this.setState({
                                    focused_area: true,
                                })
                            }
                            onBlur={() =>
                                this.setState({
                                    focused_area: notes.length >= 1,
                                })
                            }
                        />
                        <label className="form-label">
                            <span>{lang.notes}</span>
                        </label>
                    </span>
                </div>

                <span className="event__newslatter">
                    <div
                        className={
                            subscribed ? 'event__newslatter__active' : null
                        }
                        onClick={() =>
                            this.setState({
                                subscribed: !subscribed,
                            })
                        }
                    >
                        {subscribed ? <img alt="check" src={check} /> : null}
                    </div>
                    <p>
                        {selected_language === 'English'
                            ? ' I would like to be added to Krembo Wings mailing list to receive future updates and/or promotions.'
                            : 'הנני מאשר/ת קבלת דיוור ו/או חומר פרסומי מעמותת “כנפיים של קרמבו”'}
                    </p>
                </span>
                <span className="event__newslatter plem">
                    <p>
                        {selected_language === 'English'
                            ? 'Krembo Wings is a registered non-profit organization and operates in accordance with the Israeli tax law under the Section 46 ordinance.'
                            : "תרומות לעמותת 'כנפיים של קרמבו' מוכרות לצרכי מס לפי סעיף 46 לפקודת מס הכנסה"}
                    </p>
                </span>

                <div className="form__bottom">
                    <div className="form__bottom__btns flex align-center">
                        <div
                            className={
                                disabled_btn
                                    ? 'submit__projects submit__projects--disabled new_btn'
                                    : 'submit__projects new_btn'
                            }
                        >
                            {!enable_igul_letova && (
                                <input type="submit" value={lang.submit} />
                            )}
                            {disabled_btn ? (
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            ) : null}
                            {showMissingInputsMEssage ? (
                                <p className="warning_msg">
                                    {lang.generalWarningMessage}
                                </p>
                            ) : null}
                        </div>
                        <h2>
                            {selected_language === 'English' ? 'Or' : 'או'}​
                        </h2>
                        <a
                            href="https://www.paypal.me/krembowings"
                            target="_blank"
                            class="new_btn"
                        >
                            <img src={Paypal} />
                        </a>
                    </div>
                    {selected_language !== 'English' && (
                        <h3 class="form__bottom-title">
                            ניתן לתרום גם בדרכים נוספות:
                        </h3>
                    )}
                    <ul>
                        <li className="flex-column">
                            <img
                                style={{
                                    height: '60px',
                                }}
                                src={phoneImg}
                                alt=""
                            />
                            <div>
                                <img className="frame" src={Frame} alt="" />
                                <h3>
                                    <img className="bg2" src={Bg2} alt="" />
                                    {selected_language === 'English'
                                        ? `Contact us`
                                        : `להרים טלפון למוקד`}
                                    {/* {lang.first_box_h2} */}
                                </h3>
                                <p>
                                    {selected_language === 'English' ? (
                                        <>
                                            <a href="tel:+972-73-3311900">
                                                +972-73-3311900
                                            </a>
                                            <br></br>
                                            <a href="mailto:internationalfund@krembo.org.il">
                                                Email
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <a href="tel:+972779555547">
                                                מוקד:
                                                <br />
                                                077-9555547
                                            </a>
                                            <a href="tel:+972098852385">
                                                משרד:
                                                <br />
                                                09-8852385
                                            </a>
                                        </>
                                    )}
                                </p>
                                <h5>{lang.first_box_h5}</h5>
                            </div>
                        </li>
                        <li>
                            <img
                                style={{
                                    height: '60px',
                                }}
                                src={penImg}
                                alt=""
                            />
                            <div>
                                <img className="frame" src={Frame} alt="" />
                                <h3>
                                    <img className="bg2" src={Bg2} alt="" />
                                    {lang.second_box_h2}
                                </h3>
                                <p>{lang.second_box_p_1}</p>
                                <p>{lang.second_box_p}</p>
                                <h5>
                                    {selected_language === 'English' ? (
                                        <>
                                            Addressed to Krembo Wings 17
                                            HaNevi'im, P.O. Box 1166 Ramat
                                            HaSharon, 4727919"
                                            <br />' Israel'
                                        </>
                                    ) : (
                                        <>
                                            לפקודת “כנפיים של קרמבו” כתובתנו -
                                            רח׳ הנביאים 17 רמה״ש 4727919 ת.ד
                                            1166
                                        </>
                                    )}
                                </h5>
                            </div>
                        </li>
                        <li>
                            <img
                                style={{
                                    height: '60px',
                                }}
                                src={hetz}
                                alt=""
                            />
                            <div>
                                <img className="frame" src={Frame} alt="" />
                                <h3>
                                    <img className="bg2" src={Bg2} alt="" />
                                    {selected_language === 'English'
                                        ? `Transfer to`
                                        : `להעביר לחשבון העמותה`}
                                </h3>
                                <p>{lang.third_box_p}</p>
                                <h5>{lang.third_box_h5}</h5>
                            </div>
                        </li>
                        <li>
                            <a
                                href="https://payments.payplus.co.il/l/b19da98f-2325-48bf-b72b-edf875c64c31"
                                target="_blank"
                            >
                                <img
                                    style={{
                                        height: '60px',
                                    }}
                                    src={Heart}
                                    alt=""
                                />
                                <div>
                                    <img className="frame" src={Frame} alt="" />
                                    <h3>
                                        <img className="bg2" src={Bg2} alt="" />
                                        {selected_language === 'English'
                                            ? `Recurring Donations`
                                            : `לתרומה בהוראת קבע`}
                                    </h3>
                                    <p>
                                        {selected_language === 'English'
                                            ? `To create a recurring donation click on the link.`
                                            : `למעבר לעמוד תשלום בהו”ק לחץ על הלינק`}
                                    </p>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </form>
        );
    }
}

export default Form;
