import React, { Component } from 'react';
import axios from 'axios';
import validator from 'validator';

class ContactForm extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            phone: '',
            email: '',
            subject: '',
            notes: false,
            name_error: true,
            phone_error: true,
            email_error: true,
            subject_error: true,
            bad_email: false,
            bad_phone: false,
            Modal: false,
            failed: false,
            succed: false,
            submitted: false,
            notSubmitted: true,
            name_focused: false,
            phone_focused: false,
            subject_focused: false,
            email_focused: false,
            note_focused: false,
        };
    }

    submitContactForm = (e) => {
        e.preventDefault();

        const {
            name,
            phone,
            email,
            subject,
            message,
            bad_email,
            disabled_btn,
        } = this.state;
        if (!name) this.setState({ name_error: false });
        if (!phone) this.setState({ phone_error: false });
        if (!email) this.setState({ email_error: false });
        if (!subject) this.setState({ subject_error: false });

        if (!validator.isEmail(email) && email.length >= 1) {
            this.setState({
                bad_email: true,
            });
        }
        if (!validator.isMobilePhone(phone) && phone.length >= 1) {
            this.setState({
                bad_phone: true,
            });
        } else if (
            !bad_email &&
            email.length > 1 &&
            phone.length >= 1 &&
            name.length >= 1
        ) {
            this.setState({ disabled_btn: true });
            if (!disabled_btn) {
                axios
                    .post(`https://api.krembo.org.il/email`, {
                        name,
                        phone,
                        email,
                        subject,
                        message,
                    })
                    .then((res) => {
                        if (res.data.ok) {
                            console.log(
                                '🚀 ~ ContactForm ~ axios.post ~ res.data:',
                                res.data,
                            );

                            this.setState({ finished: true });
                            this.setState({ finish_url: res.result });

                            setTimeout(() => {
                                this.setState({ disabled_btn: false });
                            }, 4000);

                            this.setState({ Modal: true });
                            this.setState({ succed: true });
                            setTimeout(() => {
                                this.setState({ submitted: true });
                                this.setState({ Modal: false });
                                this.setState({ succed: false });
                            }, 4000);

                            this.setState({
                                notSubmitted: false,
                                submitted: true,
                            });

                            setTimeout(() => {
                                this.setState({
                                    notSubmitted: true,
                                    submitted: false,
                                });
                            }, 5000);
                            this.setState({
                                name: '',
                                email: '',
                                phone: '',
                                subject: '',
                                message: '',
                            });
                        } else {
                            this.setState({
                                notSubmitted: false,
                                submitted: true,
                            });

                            setTimeout(() => {
                                this.setState({
                                    notSubmitted: true,
                                    submitted: false,
                                });
                            }, 5000);
                            this.setState({
                                registration_failed: true,
                                submit: false,
                                finished: true,
                            });
                            this.setState({ Modal: true });
                            this.setState({ failed: true });
                            setTimeout(() => {
                                this.setState({ submitted: true });

                                this.setState({ failed: false });
                            }, 4000);
                        }
                    });
            }
        }
    };

    clickOutside(e) {
        const { subject_focused } = this.state;
        if (
            (subject_focused &&
                e.target.classList.value !== 'contact__subject__list') ||
            (e.target.classList.value !== 'contact__subject' && subject_focused)
        ) {
            this.setState({
                subject_focused: false,
            });
        }
    }

    render() {
        const {
            name_focused,
            phone_focused,
            subject_focused,
            email_focused,
            note_focused,
            name,
            name_error,
            phone,
            phone_error,
            email,
            email_error,
            bad_phone,
            bad_email,
            subject,
            subject_error,
            message,
            submitted,
            notSubmitted,
            succed,
        } = this.state;

        const { lang } = this.props;
        return (
            <form
                className="contact__form"
                onSubmit={(e) => this.submitContactForm(e)}
                onClick={(e) => this.clickOutside(e)}
            >
                {succed ? (
                    <div className="contact__success">
                        <h1>{lang.send_succeded}</h1>
                    </div>
                ) : null}
                <h1>{lang.form_h1}</h1>
                <section className="contact__form__flex">
                    <div className={name_focused ? 'focused__contact' : null}>
                        <span>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) =>
                                    this.setState({ name: e.target.value })
                                }
                                onFocus={() =>
                                    this.setState({
                                        name_error: true,
                                        name_focused: true,
                                    })
                                }
                                onBlur={() =>
                                    this.setState({
                                        name_error: name.length >= 1,
                                        name_focused: name.length >= 1,
                                    })
                                }
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                            >
                                <g>
                                    <g opacity=".8">
                                        <path
                                            fill={
                                                name_focused
                                                    ? '#4a90e2'
                                                    : '#424242'
                                            }
                                            d="M18 10c0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8 8-3.58 8-8zm2 0c0 5.53-4.49 10-10.01 10S0 15.53 0 10 4.47 0 9.99 0C15.52 0 20 4.47 20 10zm-8.47-1.53a.744.744 0 0 1 .01-1.06l.883-.88a1 1 0 0 1 1.414 0l.882.88c.291.29.291.77 0 1.06a.758.758 0 0 1-1.063 0l-.531-.53-.531.53a.758.758 0 0 1-1.063 0zm-6.28 4.28c-.19-.33.06-.75.44-.75h8.63c.39 0 .63.42.44.75a5.495 5.495 0 0 1-4.755 2.75 5.495 5.495 0 0 1-4.755-2.75zm1.63-4.81l-.53.53c-.29.29-.77.29-1.06 0a.754.754 0 0 1 0-1.06l.89-.88a.996.996 0 0 1 1.41 0l.88.88c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0z"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <label className="contact-label" htmlFor="name">
                            <span>{lang.name}</span>
                        </label>
                        {name_error ? null : (
                            <h4 className="error_msg">{lang.name_fill}</h4>
                        )}
                    </div>
                    <div className={phone_focused ? 'focused__contact' : null}>
                        <span>
                            <input
                                type="text"
                                name="phone"
                                value={phone}
                                onChange={(e) =>
                                    this.setState({
                                        phone: e.target.value,
                                        bad_phone: false,
                                    })
                                }
                                onFocus={() =>
                                    this.setState({
                                        phone_error: true,
                                        phone_focused: true,
                                    })
                                }
                                onBlur={() =>
                                    this.setState({
                                        phone_error: phone.length >= 1,
                                        phone_focused: phone.length >= 1,
                                    })
                                }
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="22"
                                viewBox="0 0 16 22"
                            >
                                <g>
                                    <g opacity=".8">
                                        <path
                                            fill={
                                                phone_focused
                                                    ? '#4a90e2'
                                                    : '#424242'
                                            }
                                            fillOpacity=".8"
                                            d="M8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6-8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM2 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 18c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <label className="contact-label" htmlFor="phone">
                            <span>{lang.phone}</span>
                        </label>
                        {phone_error && !bad_phone ? null : bad_phone ? (
                            <h4 className="error_msg">{lang.bad_phone}</h4>
                        ) : (
                            <h4 className="error_msg">{lang.phone_fill}</h4>
                        )}
                    </div>
                    <div className={email_focused ? 'focused__contact' : null}>
                        <span>
                            <input
                                type="text"
                                name="email"
                                value={email}
                                onChange={(e) =>
                                    this.setState({
                                        email: e.target.value,
                                        bad_email: false,
                                    })
                                }
                                onFocus={() =>
                                    this.setState({
                                        email_error: true,
                                        email_focused: true,
                                    })
                                }
                                onBlur={() =>
                                    this.setState({
                                        email_error: email.length >= 1,
                                        email_focused: email.length >= 1,
                                    })
                                }
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="16"
                                viewBox="0 0 20 16"
                            >
                                <g>
                                    <g opacity=".8">
                                        <path
                                            fill={
                                                email_focused
                                                    ? '#4a90e2'
                                                    : '#424242'
                                            }
                                            d="M17.6 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L2.4 4.25a.85.85 0 1 1 .9-1.44L10 7l6.7-4.19a.85.85 0 1 1 .9 1.44zM18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <label className="contact-label" htmlFor="email">
                            <span>{lang.email}</span>
                        </label>
                        {email_error && !bad_email ? null : bad_email ? (
                            <h4 className="error_msg">{lang.bad_email}</h4>
                        ) : (
                            <h4 className="error_msg">{lang.email_fill}</h4>
                        )}
                    </div>
                    <div
                        className={
                            subject_focused
                                ? 'focused__contact contact__select__subject contact__select__subject--active'
                                : 'contact__select__subject'
                        }
                    >
                        {/* {subject_error ? null : <h4 className='error_msg'>{lang.subject_fill}</h4>} */}
                        <section>
                            <h2
                                onClick={() =>
                                    this.setState({
                                        subject_focused: !subject_focused,
                                    })
                                }
                                className={subject ? 'label__heading' : null}
                            >
                                {lang.subject_select}
                            </h2>
                            {subject ? (
                                <h2
                                    onClick={() =>
                                        this.setState({
                                            subject_focused: !subject_focused,
                                        })
                                    }
                                >
                                    {subject}
                                </h2>
                            ) : null}
                            <ul className="contact__subject__list">
                                <li
                                    className="contact__subject"
                                    onClick={() =>
                                        this.setState({
                                            subject: lang.subject_1,
                                            subject_focused: false,
                                        })
                                    }
                                >
                                    {lang.subject_1}
                                </li>
                                <li
                                    className="contact__subject"
                                    onClick={() =>
                                        this.setState({
                                            subject: lang.subject_2,
                                            subject_focused: false,
                                        })
                                    }
                                >
                                    {lang.subject_2}
                                </li>
                                <li
                                    className="contact__subject"
                                    onClick={() =>
                                        this.setState({
                                            subject: lang.subject_3,
                                            subject_focused: false,
                                        })
                                    }
                                >
                                    {lang.subject_3}
                                </li>
                                <li
                                    className="contact__subject"
                                    onClick={() =>
                                        this.setState({
                                            subject: lang.subject_4,
                                            subject_focused: false,
                                        })
                                    }
                                >
                                    {lang.subject_4}
                                </li>
                            </ul>
                        </section>
                    </div>
                    <div
                        className={
                            note_focused
                                ? 'focused__contact contact__textarea'
                                : 'contact__textarea'
                        }
                    >
                        <textarea
                            name="notes"
                            value={message}
                            onChange={(e) =>
                                this.setState({ message: e.target.value })
                            }
                            onFocus={() =>
                                this.setState({ note_focused: true })
                            }
                            onBlur={() =>
                                this.setState({ note_focused: message })
                            }
                        ></textarea>
                        <label
                            style={{ left: '20px' }}
                            className="contact-label"
                            htmlFor="notes"
                        >
                            <span>{lang.messgae}</span>
                        </label>
                    </div>
                </section>

                <input type="submit" value={lang.send} />
            </form>
        );
    }
}

export default ContactForm;
