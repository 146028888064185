import React, { Component } from 'react';

class ContactManagersMap extends Component {
    render() {
        const { managers, lang, language } = this.props;

        const myManagers = [
            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/webp-express/webp-images/uploads/2020/06/talya.jpg.webp',
                ManageName: "טליה הראל בז'רנו",
                ManageNameEn: 'Talia Harel Bejerano',
                ManageEmail: 'talia@krembo.org.il',
                Manageposition: 'מנכ"לית',
                ManagepositionEn: 'Chief Executive Officer',
            },
            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/webp-express/webp-images/uploads/2021/05/cropped-krembo-favicon-512white-background.png.webp',
                ManageName: 'טלי גבריאל כהן',
                ManageNameEn: 'Tali Gabriel Cohen',
                ManageEmail: 'talic@krembo.org.il',
                Manageposition: `סמנכ"לית פיתוח עסקי`,
                ManagepositionEn: 'Business Development Vice President',
            },
            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/webp-express/webp-images/uploads/2021/05/cropped-krembo-favicon-512.png.webp',
                ManageName: 'איתי זיתון זוהר',
                ManageNameEn: 'Gil Doron',
                ManageEmail: 'itayz@krembo.org.il',
                Manageposition: 'סמנכ"ל תפעול ומפעלים',
                ManagepositionEn: 'VP, Operations and Strategic Partnerships',
            },
            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/webp-express/webp-images/uploads/2021/05/cropped-krembo-favicon-512white-background.png.webp',
                ManageName: 'נאור גליל',
                ManageNameEn: 'Naor Galil',
                ManageEmail: 'naor@krembo.org.il',
                Manageposition: 'סמנכ"ל פעילות ארצית',
                ManagepositionEn: 'VP of Programs',
            },
            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/webp-express/webp-images/uploads/2021/11/%D7%90%D7%9C%D7%93%D7%93-%D7%90%D7%95%D7%A4%D7%99%D7%A8.jpg.webp',
                ManageName: 'אלדד אופיר',
                ManageNameEn: 'Eldad Ofir',
                ManageEmail: 'eldad@krembo.org.il',
                Manageposition: 'סמנכ"ל כספים',
                ManagepositionEn: 'Chief Financial Officer',
            },
            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/webp-express/webp-images/uploads/2020/06/%D7%98%D7%9C.jpg.webp',
                ManageName: 'טל בן אריה מנשס',
                ManageNameEn: 'Tal Ben Arie Menashes',
                ManageEmail: 'hr@krembo.org.il',
                Manageposition: `סמנכ"לית משאבי אנוש`,
                ManagepositionEn: 'VP, Human Resources',
            },
            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/webp-express/webp-images/uploads/2021/05/cropped-krembo-favicon-512white-background.png.webp',
                ManageName: 'שרון ארבר',
                ManageNameEn: 'Sharon Arber',
                ManageEmail: 'krembo_law@krembo.org.il',
                Manageposition: `יועצת משפטית`,
                ManagepositionEn: 'Legal Advisor',
            },
            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/webp-express/webp-images/uploads/2021/05/cropped-krembo-favicon-512white-background.png.webp',
                ManageName: 'אלה מעוז בן זאב',
                ManageNameEn: 'Ella Maoz Ben Zeev',
                ManageEmail: 'ella@krembo.org.il',
                Manageposition: `סמנכ"לית אגף חינוך`,
                ManagepositionEn: 'Head of Alumni Programs',
            },
            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/uploads/2025/01/cd591aea-801a-4ed4-be5b-20cf97611007-1_all_306962.jpg',
                ManageName: 'עדי אליהו',
                ManageNameEn: 'Adi Eliyahu',
                ManageEmail: 'adi.e@krembo.org.il',
                Manageposition: `סמנכ"לית שותפויות`,
                ManagepositionEn: 'VP of Partnerships',
            },
            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/webp-express/webp-images/uploads/2022/11/%D7%A2%D7%99%D7%93%D7%95-scaled.jpg.webp',
                ManageName: 'עידו ברנע',
                ManageNameEn: 'Ido Berna',
                ManageEmail: 'ido@krembo.org.il',
                Manageposition: `מנהל מערכות מידע`,
                ManagepositionEn: 'IT Manager',
            },

            {
                ManageImg:
                    'https://www.krembo.org.il/wp-content/webp-express/webp-images/uploads/2021/05/cropped-krembo-favicon-512white-background.png.webp',
                ManageName: 'נטלי אביאלי',
                ManageNameEn: 'Natali Avieli',
                ManageEmail: 'nataliea@krembo.org.il',
                Manageposition: `מנהלת שיווק`,
                ManagepositionEn: 'Marketing Manager',
            },
        ];
        return (
            <div className="contact__managers__wrap">
                <h1>{lang.managers_h1}</h1>
                {myManagers.map((n, i) => (
                    <div className="contact__manager" key={i}>
                        <aside>
                            <img src={n.ManageImg} alt="" />
                            <span>
                                <h2>
                                    {language === 'hebrew'
                                        ? n.ManageName
                                        : n.ManageNameEn}
                                </h2>
                                <h3>
                                    {language === 'hebrew'
                                        ? n.Manageposition
                                        : n.ManagepositionEn}
                                </h3>
                            </span>
                        </aside>

                        <a href={`mailto:${n.ManageEmail}`}>
                            <button>{lang.send}</button>
                        </a>
                    </div>
                ))}
            </div>
        );
    }
}

export default ContactManagersMap;
